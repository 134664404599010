import { useEffect, useState } from "react";

export default function useTheme(initialWidth = window.innerWidth, initialHeight = window.innerHeight) {
  const [breakpoint, setBreakPoint] = useState(initialWidth);
  const [heightpoint, setHeightpoint] = useState(initialHeight);
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  });
  const handleResize = () => {
    setWindowSize({
      width: window.innerWidth,
      height: window.innerHeight
    });
  };
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    handleResize();

    // if (0 < windowSize.width && windowSize.width < 1024) {
    //   setBreakPoint(0);
    // }

    // if (1024 < windowSize.width && windowSize.width < 1920) {
    //   setBreakPoint(1024);
    // }
    // if (windowSize.width >= 1920) {
    //   setBreakPoint(1920);
    // }

    return () => window.removeEventListener("resize", handleResize);
  }, [windowSize.width]);

  useEffect(() => {
    handleResize();
  }, []);

  return {
    breakpoint,
    heightpoint
  };
}
