import { CaptureConsole } from "@sentry/integrations";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import RootApp from 'App';
import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import "i18n";
import './index.css';

Sentry.init({
  dsn: `${process.env.REACT_APP_SENTRY_DSN}`,
  integrations: [
    new BrowserTracing(),
    new CaptureConsole({
      levels: ["error"]
    })
  ]
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RootApp />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
