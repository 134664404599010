import React from "react";
import "./style.scss";

const LoadingV2 = () => {
  return (
    <div className="loading-container">
      <div className="loader">
        <div className="spinner">
          <div className="container-hex">
            <div className="hex0"></div>
            <div className="hex120"></div>
            <div className="hex240"></div>
            <div className="spinner">
              <div className="container-hex">
                <div className="hex0"></div>
                <div className="hex120"></div>
                <div className="hex240"></div>
                <div className="spinner">
                  <div className="container-hex">
                    <div className="hex0"></div>
                    <div className="hex120"></div>
                    <div className="hex240"></div>
                    <div className="spinner">
                      <div className="container-hex">
                        <div className="hex0"></div>
                        <div className="hex120"></div>
                        <div className="hex240"></div>
                        <div className="spinner">
                          <div className="container-hex">
                            <div className="hex0"></div>
                            <div className="hex120"></div>
                            <div className="hex240"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoadingV2;