/* eslint-disable react/jsx-no-comment-textnodes */
import { network } from "../config";
import { ethers } from "ethers";
import upbondServices from "lib/UpbondEmbed";
import { useEffect, useState } from "react";
import { toast, Toaster } from "react-hot-toast";
import Web3 from "web3";
import erc20Abi from "../json/erc20Abi.json";
import erc721Abi from "../json/erc721Abi.json";
import erc1155Abi from "../json/erc1155Abi.json";
import { Stack } from "@mui/material";
import { RelayProvider } from "@opengsn/provider";
import MinterAbi from "../json/Mint.json";
import DefaultProfileImage from "assets/images/default-photo.svg";
import { truncateAddress } from "helpers/strings";
import { useTranslation } from "react-i18next";
import LoadingV2 from "component/loading/loading_v2";
import Joyride, { STATUS } from "react-joyride";
import useTheme from "../hooks/useTheme";
import TutorialContent from 'component/walkthrough/content';
import "./index.css";

/* 
  Read this:
  This concept actually can be also using hooks (functional), you may can decide what you want to do
  if you're using hooks, sure you can put the new Upbond({}) on the useState.
  We're using this example because we're usually using this method for implementing the @upbond/upbond-embed lib
*/

const Embed = () => {
  const { t, i18n } = useTranslation();
  const { breakpoint, heightpoint } = useTheme();

  const [language, setlanguage] = useState("en");
  const [loading, setLoading] = useState(false);
  const [account, setAccount] = useState(null);
  const [userInfo, setUserInfo] = useState(null);
  const [signInfo, setSignInfo] = useState(null);
  const [btnLoading, setBtnLoading] = useState(false);
  const [isShowUserInfo, setIsShowUserInfo] = useState(false);
  const [showBc, setShowBc] = useState(false);
  const [isCopy, setIsCopy] = useState(false);
  const [txResult, setTxResult] = useState({});
  const [bcState, setBcState] = useState({
    address: "",
    chainId: 0,
    balance: 0,
    erc20: 0,
  });
  const [isApproved, setIsApproved] = useState(false);
  const [approvedRes, setApprovedRes] = useState("0");
  const [toTransferAddress, setToTransferAddress] = useState(
    "0x673d6c086E84e9Db30bD20450e4A4c3D5f627824"
  );
  const [toTransferAddressERC721, setToTransferAddressERC721] = useState(
    "0x673d6c086E84e9Db30bD20450e4A4c3D5f627824"
  );
  const [tokenIdERC721, setERC721TokenId] = useState("");
  const [contractERC721Address, setERC721ContractAddress] = useState(
    "0x86e9B9A25b6ebf006Ad63220890ed48513d195CD"
  );

  const [toTransferAddressERC1155, setToTransferAddressERC1155] = useState(
    "0x673d6c086E84e9Db30bD20450e4A4c3D5f627824"
  );
  const [tokenIdERC1155, setERC1155TokenId] = useState("");
  const [contractERC1155Address, setERC1155ContractAddress] = useState(
    "0x86e9B9A25b6ebf006Ad63220890ed48513d195CD"
  );
  const [amountTransferERC1155, setAmountTransferERC1155] = useState("");

  const [amountTransfer, setAmountTransfer] = useState("2");
  // const [consentData, setConsentData] = useState({});
  // const [allData, setAllData] = useState(null);

  const [currentStep, setCurrentStep] = useState(0);
  const [upbond, setUpbond] = useState(null);
  const [upbondProvider, setUpbondProvider] = useState(null);
  const [run, setRun] = useState(false);

  const handleJoyrideCallback = (data) => {
    const { status } = data;
    const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED];

    if (finishedStatuses.includes(status)) {
      setRun(false);
    }
  };

  const tutorialData = [
    {
      title: t("tour.title_step1"),
      content: t("tour.content_step1"),
      button: t("tour.button_step1"),
      pointTo: "body",
      placement: "center",
    },
    {
      title: t("tour.title_step2"),
      content: t("tour.content_step2"),
      button: t("tour.button_step2"),
      pointTo: ".toggle-info",
      placement: "top",
    },
    {
      title: t("tour.title_step3"),
      content: t("tour.content_step3"),
      button: t("tour.button_step3"),
      pointTo: ".action-info",
      placement: "bottom",
    },
    {
      title: t("tour.title_step4"),
      content: t("tour.content_step4"),
      button: t("tour.button_step4"),
      pointTo: "#upbondIframe",
      placement: "top",
    },
  ];

  const [steps] = useState(
    tutorialData.map((ele, idx) => {
      return {
        content: (
          <TutorialContent
            title={ele.title}
            content={ele.content}
            button={ele.button}
            onClickNext={() => setCurrentStep(idx + 1)}
            curStep={idx}
            setRun={setRun}
            breakpoint={breakpoint}
            heightpoint={heightpoint}
            steps={tutorialData}
            t={t}
          />
        ),
        placement: ele.placement,
        target: ele.pointTo,
      };
    })
  );

  const _upbond = upbondServices.upbond.provider;

  useEffect(() => {
    const lang = localStorage.getItem("lang");
    lang === "ja" ? setlanguage("ja") : setlanguage("en");
  }, [language]);

  const checkAllowance = async () => {
    try {
      if (_upbond && account) {
        const web3 = new Web3(_upbond);
        const tupbToken = new web3.eth.Contract(
          erc20Abi,
          "0x28111701BD21677EE35dBa0539A55ec22B262847"
        );
        const allowance = await tupbToken.methods
          .allowance(account[0], "0xf5de760f2e916647fd766B4AD9E85ff943cE3A2b")
          .call();
        if (allowance !== "0") {
          setIsApproved(true);
          setApprovedRes(allowance);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const rehydrate = async () => {
      const web3 = new Web3(_upbond);
      const accs = await web3.eth.getAccounts();
      console.log(`web3Accounts: `, accs);
      if (accs.length > 0) {
        setAccount(accs);
        handleClickStart();
      }
    };

    if (_upbond) {
      rehydrate();
    }
  }, [_upbond]);

  useEffect(() => {
    if (upbondServices.isLoggedIn) {
      if (upbondServices.upbond.provider) {
        setUpbondProvider(_upbond);
      }
    }
  }, []);

  useEffect(() => {
    const initUpbond = async () => {
      setLoading(true);
      try {
        await upbondServices.init();
        setUpbond(upbondServices);
        setUpbondProvider(_upbond);
      } catch (error) {
        console.error(`Error initialization: `, error);
      }
      setLoading(false);
    };
    if (account) {
      return;
    }
    initUpbond();
  }, []);

  const getBlockchainInfo = async (refresh = false) => {
    if (!refresh && showBc) {
      setShowBc(false);
      return;
    }

    const web3 = new Web3(_upbond);
    const [accounts, chainId] = await Promise.all([
      web3.eth.getAccounts(),
      web3.eth.getChainId(),
    ]);

    if (chainId === 80001) {
      const tupbToken = new web3.eth.Contract(
        erc20Abi,
        "0x28111701BD21677EE35dBa0539A55ec22B262847"
      );
      const tupbBalance = await tupbToken.methods.balanceOf(accounts[0]).call();
      setBcState((curr) => ({
        ...curr,
        erc20:
          ethers.utils.formatEther(tupbBalance) + " (TUPB | Testnet UPBOND)",
      }));
    }
    if (accounts) {
      const balance = await web3.eth.getBalance(accounts[0]);
      setShowBc(true);
      setBcState((curr) => ({
        ...curr,
        address: accounts[0],
        balance: `${parseInt(balance) / Math.pow(10, 18)} (MATIC)`,
        chainId,
      }));
    }
  };

  const login = async () => {
    // setLoading(true);
    try {
      console.log(_upbond);
      const login = await upbondServices.login();
      if (login.data !== null) {
        setAccount(login.accounts);
        setLoading(false);
        return;
      }
      setLoading(false);
    } catch (error) {
      toast.error(error.message || "Some error occured");
      setLoading(false);
      console.error(error);
      throw new Error(error);
    }
  };

  const getUser = async () => {
    if (isShowUserInfo) {
      setIsShowUserInfo(false);
      return;
    }
    setLoading(true);
    try {
      const getData = await upbondServices.getUserInfo();
      setUserInfo(getData);
      setIsShowUserInfo(true);
      setLoading(false);
    } catch (error) {
      toast.error(error.message || "Some error occured");
      console.error(error, "@errorOnGetUser");
      setIsShowUserInfo(true);
      setLoading(false);
    }
  };

  const signTransaction = async () => {
    try {
      setBtnLoading(true);
      setIsCopy(false);
      setLoading(true);
      const msgHash = Web3.utils.keccak256(
        "Signing Transaction for Upbond Embed!"
      );
      const signedMsg = await upbondServices.signTransaction(
        msgHash,
        account[0]
      );
      console.log(signedMsg);
      setSignInfo(signedMsg);
      setBtnLoading(false);
    } catch (error) {
      setBtnLoading(false);
      toast.error(error.message || "Some error occured");
      console.error(error);
      setLoading(false);
    }
  };

  const minting = async () => {
    setBtnLoading(true);
    const theAddress = account[0];
    try {
      const relay = await RelayProvider.newProvider({
        provider: _upbond,
        config: {
          paymasterAddress: "0xD97B7555aaB3F89e36FE992d96454C41B2C5CEb8",
          relayLookupWindowBlocks: 990,
          relayRegistrationLookupBlocks: 990,
          pastEventsQueryMaxPageSize: 990,
        },
      }).init();
      const provider = new ethers.providers.Web3Provider(relay);
      const signer = await provider.getSigner();
      const factory = new ethers.Contract(
        "0xe697d83c65C1c450C19906AE10e5770C42D871aa", //nft contract
        MinterAbi,
        signer
      );
      const getDomain = await factory.DOMAIN_SEPARATOR();
      const keccak = ethers.utils.keccak256(
        ethers.utils.solidityPack(
          ["bytes32", "address"],
          [getDomain, theAddress]
        )
      );
      const claimNft = await factory.claim("1", keccak);
      await claimNft.wait();
      toast.success(`You've been claim NFT!`);
      setBtnLoading(false);
    } catch (error) {
      setBtnLoading(false);
      console.log(error, "errorrrrrrrr");
    }
  };

  const signWeb3Token = async () => {
    try {
      setBtnLoading(true);
      setIsCopy(false);
      const signedMsg = await upbondServices.signWeb3Token(account[0]);
      if (signedMsg) {
        setBtnLoading(false);
        setSignInfo(`${signedMsg}`);
      } else {
        setBtnLoading(false);
        setSignInfo(t("response.rejected"));
      }
    } catch (error) {
      setBtnLoading(false);
      toast.error(error.message || t("response.error"));
    }
  };

  const deploy = async () => {
    try {
      setBtnLoading(true);
      const provider = new ethers.providers.Web3Provider(_upbond);
      const signer = provider.getSigner();
      const gasPrice = await provider.getGasPrice();
      const tx = {
        to: account[0],
        // Convert currency unit from ether to wei
        value: ethers.utils.parseEther("0.0001").toString(),
        gasLimit: 30000,
        gasPrice,
      };
      // Send a transaction
      signer
        .sendTransaction(tx)
        .then((txObj) => {
          console.log("txHash", txObj);
          setTxResult({ hash: txObj.hash });
          // => 0x9c172314a693b94853b49dc057cf1cb8e529f29ce0272f451eea8f5741aa9b58
          // A transaction result can be checked in a etherscan with a transaction hash which can be obtained here.
        })
        .catch((err) =>
          toast.error(
            err.data.code == "-32000"
              ? "Insufficient Balance"
              : err.data.message
          )
        );
      setBtnLoading(false);
    } catch (error) {
      setBtnLoading(false);
      console.error(error);
      toast.error(error.message || "Error occured!");
    }
  };

  const approve = async () => {
    try {
      await checkAllowance();

      if (isApproved) {
        toast.error(`you're approved`);
        return;
      }

      setBtnLoading(true);
      const web3 = new Web3(_upbond);
      const tupbToken = new web3.eth.Contract(
        erc20Abi,
        "0x28111701BD21677EE35dBa0539A55ec22B262847"
      );
      const balance = await tupbToken.methods.balanceOf(account[0]).call();
      if (balance !== "0") {
        await tupbToken.methods
          .approve(
            "0xf5de760f2e916647fd766B4AD9E85ff943cE3A2b",
            "115792089237316195423570985008687907853269984665640564039457584007913129639935"
          )
          .send({ from: account[0] });
        setBtnLoading(false);
        setIsApproved(true);
        toast.success(`Your account has been approved`);
      } else {
        setBtnLoading(false);
        toast.error(`You don't have TUPB token`);
        return;
      }
    } catch (error) {
      setBtnLoading(false);
      console.error(error);
      toast.error(error.message || "Error occured!");
    }
  };

  const claimTUPBToken = async () => {
    try {
      setBtnLoading(true);
      const wallet = new ethers.Wallet(
        "1a7b09c99e5dbba3bc614003afc86a71965f03ab204e2fe9f01801badcbb1cbf",
        new ethers.providers.JsonRpcProvider(network.rpcUrl)
      );
      const tupbTokenContract = new ethers.Contract(
        "0x28111701BD21677EE35dBa0539A55ec22B262847",
        erc20Abi,
        wallet
      );
      const transfer = await tupbTokenContract.transfer(
        account[0],
        ethers.utils.parseEther("2"),
        {
          gasLimit: 61000,
        }
      );
      await transfer.wait();
      await getBlockchainInfo(true);
      setBtnLoading(false);
      toast.success(`Success transfer TUPB token to your account`);
    } catch (error) {
      setBtnLoading(false);
      console.error(error);
      toast.error(error.message || "Error occured!");
    }
  };

  const transfer = async () => {
    try {
      setBtnLoading(true);
      const web3 = new Web3(_upbond);
      const tupbToken = new web3.eth.Contract(
        erc20Abi,
        "0x28111701BD21677EE35dBa0539A55ec22B262847"
      );
      const balance = await tupbToken.methods.balanceOf(account[0]).call();
      const decimal = await tupbToken.methods.decimals().call();
      console.log(
        decimal,
        "@decimal?",
        ethers.utils.parseUnits(balance, decimal).toString()
      );
      if (
        parseInt(balance) > 0 &&
        parseInt(amountTransfer) < parseInt(balance)
      ) {
        await tupbToken.methods
          .transfer(
            toTransferAddress,
            ethers.utils.parseUnits(amountTransfer, 18)
          )
          .send({ from: account[0] });
        toast.success(`Success Transfer to ${toTransferAddress}`);
        setBtnLoading(false);
      } else {
        setBtnLoading(false);
        toast.error(`Amount exceed balance`);
      }
    } catch (error) {
      setBtnLoading(false);
      console.error(error);
      toast.error(error.message || "Error occured!");
    }
  };

  const transferERC721 = async () => {
    try {
      setBtnLoading(true);
      const web3 = new Web3(_upbond);
      const erc721Contract = new web3.eth.Contract(
        erc721Abi,
        contractERC721Address
      );
      await erc721Contract.methods
        .safeTransferFrom(
          account[0],
          toTransferAddressERC721,
          tokenIdERC721,
          "0x"
        )
        .send({ from: account[0] });
      toast.success(`Success Transfer to ${toTransferAddressERC721}`);
      setBtnLoading(false);
    } catch (error) {
      setBtnLoading(false);
      console.error(error);
      toast.error(error.message || "Error occured!");
    }
  };

  const transferERC1155 = async () => {
    try {
      setBtnLoading(true);
      const web3 = new Web3(_upbond);
      const erc1155Contract = new web3.eth.Contract(
        erc1155Abi,
        contractERC1155Address
      );
      await erc1155Contract.methods
        .safeTransferFrom(
          account[0],
          toTransferAddressERC1155,
          tokenIdERC1155,
          amountTransferERC1155,
          "0x"
        )
        .send({ from: account[0] });
      toast.success(`Success Transfer to ${toTransferAddressERC1155}`);
      setBtnLoading(false);
    } catch (error) {
      setBtnLoading(false);
      console.error(error);
      toast.error(error.message || "Error occured!");
    }
  };

  // const openWallet = () => {
  //   upbondServices.upbond.showWallet("home");
  // };

  useEffect(() => {
    const initLayout = async () => {
      console.log(`Initializing`, _upbond);
      if (_upbond) {
        setLoading(true);
        const web3 = new Web3(_upbond);
        const account = await web3.eth.getAccounts();
        setAccount(account);
        setLoading(false);
      }
    };
    initLayout();
  }, [_upbond]);

  useEffect(() => {
    if (_upbond) {
      if (_upbond.on) {
        _upbond.on("accountsChanged", (accounts) => {
          setAccount(accounts);
          console.log(`Account changed: ${accounts}`);
        });

        _upbond.on("chainChanged", (res) => {
          console.log(`Chain changed on: ${res}`);
          setUpbondProvider(_upbond);
          console.log("@@@ provider set to ->>", _upbond);
        });

        _upbond.on("connect", (res) => {
          console.log("onConnect?", res);
        });
      }
    }
  }, [_upbond]);

  const ProfileImage = () => {
    if (userInfo && userInfo.profileImage) {
      return (
        <img
          className="inline-block h-14 w-14 rounded-full my-3"
          alt={userInfo.name}
          src={userInfo.profileImage}
          onError={({ currentTarget }) => {
            currentTarget.src = DefaultProfileImage;
            currentTarget.onerror = null;
          }}
        />
      );
    } else {
      return (
        <img
          className="inline-block h-14 w-14 rounded-full"
          alt={userInfo.name}
          src={DefaultProfileImage}
        />
      );
    }
  };

  const handleClickStart = () => {
    setRun(true);
  };

  const changeLanguage = () => {
    const setupLang = language === "en" ? "ja" : "en";
    i18n.changeLanguage(setupLang);
    setlanguage(setupLang);
    localStorage.setItem("lang", setupLang);
    window.location.reload();
  };

  const ShowWalkThrough = () => {
    return (
      <Joyride
        callback={handleJoyrideCallback}
        continuous
        hideCloseButton
        run={run}
        scrollToFirstStep
        showProgress={false}
        showSkipButton
        stepIndex={currentStep}
        steps={steps}
        locale={{ back: "" }}
        styles={{
          buttonNext: {
            display: "none",
          },
          buttonSkip: {
            display: "none",
          },
          buttonBack: {
            display: "none",
          },
          options: {
            arrowColor: "#5167A9",
            backgroundColor: "#5167A9",
            primaryColor: "#000",
            textColor: "#fff",
            width: 700,
            zIndex: 1000,
          },
          arrowContainer: {
            bottom: "1px",
          },
          tooltip: {
            backgroundColor: "#5167A9",
            // height: breakpoint > 1024 ? "40vh" : breakpoint > 400 ? "40vh" : "45vh",
            minHeight: heightpoint > 850 ? "40vh" : "48vh",
            padding: breakpoint > 400 && heightpoint > 850 ? "1.5rem 0px 3rem" : "0rem 0px 1rem",
            borderRadius: "12px",
          },
          tooltipContainer: {
            height: "100%",
          },
          tooltipContent: {
            padding: "10px",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          },
        }}
      />
    )
  }

  const networkEnv = process.env.REACT_APP_SHOW_ERC_UTILS;

  return (
    <div className="mx-auto px-2 sm:px-6 lg:px-8 pb-10 overflow-hidden">
      <ShowWalkThrough />

      <div className="flex justify-end items-center toggle-lang my-4 text-md">
        <button
          className="bg-[#b0e0e6] flex justify-center items-center gap-3 rounded-lg py-1 px-4"
          onClick={changeLanguage}
        >
          <span
            className={
              language === "en"
                ? "font-bold"
                : "text-gray-500 font-normal text-xs"
            }
          >
            English
          </span>{" "}
          |
          <span
            className={
              language === "ja"
                ? "font-bold"
                : "text-gray-500 font-normal text-xs"
            }
          >
            日本語
          </span>
        </button>
      </div>

      <header className="w-full overflow-hidden px-2">
        <p className="text-center text-xl font-bold my-3 lg:text-2xl">
          {t("title.title-page")}
        </p>

        <div className="mt-4 w-full px-4 flex justify-center">
          <p className="text-center font-semibold">{t("title.subtitle")}</p>
        </div>

        {account && account.length > 0 ? (
          <div className="max-w-5xl mx-auto">
            {/* <div className=" w-max mx-auto flex justify-center gap-3 py-2 px-6 mb-4 font-semibold rounded-lg shadow-[inset_0_0_16px_-4px_#aaa]">
              <div className="text-gray-500">{t("text.account")} :</div>
              <div className="max-[475px]:hidden">{account}</div>
              <div className="2xs:hidden">{truncateAddress(account[0], 6, 6)}</div>
            </div> */}

            <div className="subtitle mx-auto my-6 font-semibold text-center text-sm">
              {t("title.section-title-1")}
            </div>

            <div className="w-full flex justify-center items-center flex-col sm:flex-row space-x-0 sm:space-x-3 mt-3">
              <div className="toggle-info flex flex-col w-full sm:w-max sm:flex-row justify-center gap-3">
                <button
                  type="button"
                  disabled={btnLoading}
                  className="items-center px-4 py-3 md:py-2 border border-transparent text-sm font-medium rounded-xl shadow-sm text-white bg-green-500 hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                  onClick={getUser}
                >
                  {t("buttons.toggle_user_info")}
                </button>
                <button
                  type="button"
                  disabled={btnLoading}
                  className="items-center px-4 py-3 md:py-2 border border-transparent text-sm font-medium rounded-xl shadow-sm text-white bg-green-500 hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                  onClick={() => getBlockchainInfo(false)}
                >
                  {t("buttons.toggle_blockchain_info")}
                </button>
              </div>
            </div>

            {/* User Info */}
            {userInfo && isShowUserInfo && (
              <div className="max-w-lg mx-auto text-center bg-slate-200 p-3 rounded-xl my-4 overflow-hidden">
                <p className="font-bold">{t("text.user_info")}</p>
                <ProfileImage />
                <table className="sm:max-w-lg 4xs:w-full mx-auto border-spacing-x-2 border-separate text-sm">
                  <tbody>
                    <tr>
                      <td className="text-gray-500 text-right min-w-[70px] max-w-[100px]">
                        {t("label.name")}
                      </td>
                      <td className="font-semibold text-left">
                        {userInfo?.name || <span className='font-normal text-gray-400'>Unavailable</span>}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-gray-500 text-right min-w-[70px] max-w-[100px]">
                        {t("label.email")}
                      </td>
                      <td className="font-semibold text-left">
                        {userInfo?.email || <span className='font-normal text-gray-400'>Unavailable</span>}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-gray-500 text-right min-w-[70px] max-w-[100px]">
                        {t("label.verifier")}
                      </td>
                      <td className="font-semibold text-left">
                        {userInfo?.verifier || <span className='font-normal text-gray-400'>Unavailable</span>}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            )}

            {/* Blockchain info */}
            {showBc && bcState.chainId !== 0 && (
              <div className="max-w-lg mx-auto text-center bg-slate-200 p-3 rounded-xl my-4 overflow-hidden">
                <p className="font-bold mb-3">{t("text.blockchain_info")}</p>

                <table className="max-w-lg mx-auto border-spacing-x-2 border-separate text-sm">
                  <tbody>
                    {Object.keys(bcState).map((x) => (
                      <tr key={x}>
                        <td className="text-gray-500 text-right">{x}</td>
                        <td className="font-semibold text-left">
                          {x === "address"
                            ? truncateAddress(bcState[x], 10, 6)
                            : bcState[x]}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}

            <div className="divider w-[90%] h-[1px] mt-6 mb-2 mx-auto bg-black"></div>
            <div className="subtitle mx-auto mb-6 font-semibold text-center text-sm">
              {t("title.section-title-2")}
            </div>

            <div className="action-info sm:flex sm:flex-row gap-3 4xs:flex 4xs:flex-col 4xs:justify-center">
              <button
                type="button"
                disabled={btnLoading}
                className="disabled:bg-gray-500 items-center px-4 py-3 md:py-2 text-sm font-medium rounded-xl shadow-sm text-white bg-[#4B68AE] hover:bg-[#214999] border-2 border-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                onClick={signTransaction}
              >
                {t("buttons.sign_transaction")}
              </button>
              <button
                type="button"
                disabled={btnLoading}
                className="disabled:bg-gray-500 items-center px-4 py-3 md:py-2 text-sm font-medium rounded-xl shadow-sm text-white bg-[#4B68AE] hover:bg-[#214999] border-2 border-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#4B68AE]"
                onClick={signWeb3Token}
              >
                {t("buttons.sign_web3token")}
              </button>
              <button
                type="button"
                disabled={btnLoading}
                className="disabled:bg-gray-500 items-center px-4 py-3 md:py-2 text-sm font-medium rounded-xl shadow-sm text-white bg-[#4B68AE] hover:bg-[#214999] border-2 border-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#4B68AE]"
                onClick={deploy}
              >
                {t("buttons.send_transaction")}
              </button>

              {/* //TODO: Get consent data from embed */}
              {/* <button
                type="button"
                disabled={btnLoading}
                className="disabled:bg-gray-500 items-center px-4 py-3 md:py-2 text-sm font-medium rounded-xl shadow-sm text-white bg-[#4B68AE] hover:bg-[#214999] border-2 border-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#4B68AE]"
              >
                {t("buttons.get_consent_data")}
              </button> */}

              {networkEnv === "true" && (
                <>
                  <button
                    type="button"
                    disabled={btnLoading || isApproved}
                    className="disabled:bg-gray-500 items-center px-4 py-3 md:py-2 text-sm font-medium rounded-xl shadow-sm text-white bg-[#4B68AE] hover:bg-[#214999] border-2 border-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#4B68AE]"
                    onClick={approve}
                  >
                    Approve TUPB token
                  </button>
                  <button
                    type="button"
                    disabled={btnLoading}
                    className="disabled:bg-gray-500 items-center px-4 py-3 md:py-2 text-sm font-medium rounded-xl shadow-sm text-white bg-[#4B68AE] hover:bg-[#214999] border-2 border-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#4B68AE]"
                    onClick={minting}
                  >
                    Mint
                  </button>
                  <button
                    type="button"
                    disabled={btnLoading}
                    className="disabled:bg-gray-500 items-center px-4 py-3 md:py-2 text-sm font-medium rounded-xl shadow-sm text-white bg-[#4B68AE] hover:bg-[#214999] border-2 border-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#4B68AE]"
                    onClick={claimTUPBToken}
                  >
                    Claim 2 TUPB Token
                  </button>
                </>
              )}
            </div>

            <button
              type="button"
              disabled={btnLoading}
              className="items-center sm:w-[150px] sm:relative sm:left-1/2 sm:-translate-x-1/2 4xs:w-full px-4 py-3 my-3 mx-auto md:py-2 border border-transparent text-sm font-medium rounded-xl shadow-sm text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
              onClick={async () => await upbondServices.logout()}
            >
              {t("buttons.logout")}
            </button>

            {networkEnv === "true" && (
              <Stack direction={"row"} spacing={2} mt={2}>
                <button
                  type="button"
                  disabled={btnLoading}
                  className="disabled:bg-gray-500 mt-5 items-center px-4 py-3 md:py-2 text-sm font-medium rounded-xl shadow-sm text-white bg-[#4B68AE] hover:bg-[#214999] border-2 border-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#4B68AE]"
                  onClick={transfer}
                >
                  Transfer TUPB
                </button>
                <div>
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Address
                  </label>
                  <div className="mt-1">
                    <input
                      type="text"
                      value={toTransferAddress}
                      onChange={(e) => setToTransferAddress(e.target.value)}
                      name="address"
                      id="address"
                      className="block w-full rounded-md border-gray-200 shadow-sm focus:border-[#4B68AE] focus:ring-[#4B68AE] sm:text-sm"
                      placeholder="starts with 0x"
                    />
                  </div>
                </div>
                <div>
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Amount
                  </label>
                  <div className="mt-1">
                    <input
                      type="text"
                      value={amountTransfer}
                      onChange={(e) => setAmountTransfer(e.target.value)}
                      name="amount"
                      id="amount"
                      className="block w-full rounded-md border-gray-200 shadow-sm focus:border-[#4B68AE] focus:ring-[#4B68AE] sm:text-sm"
                      placeholder="amount must be number"
                    />
                  </div>
                </div>
              </Stack>
            )}
            {networkEnv === "true" && (
              <>
                <Stack direction={"row"} spacing={2} mt={2}>
                  <button
                    type="button"
                    disabled={btnLoading}
                    className="disabled:bg-gray-500 mt-5 items-center px-4 py-3 md:py-2 text-sm font-medium rounded-xl shadow-sm text-white bg-[#4B68AE] hover:bg-[#214999] border-2 border-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#4B68AE]"
                    onClick={transferERC1155}
                  >
                    ERC 1155
                  </button>
                  <div>
                    <label
                      htmlFor="email"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Address
                    </label>
                    <div className="mt-1">
                      <input
                        type="text"
                        value={toTransferAddressERC1155}
                        onChange={(e) =>
                          setToTransferAddressERC1155(e.target.value)
                        }
                        className="block w-full rounded-md border-gray-200 shadow-sm focus:border-[#4B68AE] focus:ring-[#4B68AE] sm:text-sm"
                        placeholder="starts with 0x"
                      />
                    </div>
                  </div>
                  <div>
                    <label
                      htmlFor="email"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Amount
                    </label>
                    <div className="mt-1">
                      <input
                        type="text"
                        value={amountTransferERC1155}
                        onChange={(e) =>
                          setAmountTransferERC1155(e.target.value)
                        }
                        className="block w-full rounded-md border-gray-200 shadow-sm focus:border-[#4B68AE] focus:ring-[#4B68AE] sm:text-sm"
                        placeholder="amount must be number"
                      />
                    </div>
                  </div>
                  <div>
                    <label
                      htmlFor="email"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Token Id
                    </label>
                    <div className="mt-1">
                      <input
                        type="text"
                        value={tokenIdERC1155}
                        onChange={(e) => setERC1155TokenId(e.target.value)}
                        className="block w-full rounded-md border-gray-200 shadow-sm focus:border-[#4B68AE] focus:ring-[#4B68AE] sm:text-sm"
                        placeholder="amount must be number"
                      />
                    </div>
                  </div>
                  <div>
                    <label
                      htmlFor="email"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Contract Address
                    </label>
                    <div className="mt-1">
                      <input
                        type="text"
                        value={contractERC1155Address}
                        onChange={(e) =>
                          setERC1155ContractAddress(e.target.value)
                        }
                        className="block w-full rounded-md border-gray-200 shadow-sm focus:border-[#4B68AE] focus:ring-[#4B68AE] sm:text-sm"
                        placeholder="0x"
                      />
                    </div>
                  </div>
                </Stack>
                <Stack direction={"row"} spacing={2} mt={2}>
                  <button
                    type="button"
                    disabled={btnLoading}
                    className="disabled:bg-gray-500 mt-5 items-center px-4 py-3 md:py-2 text-sm font-medium rounded-xl shadow-sm text-white bg-[#4B68AE] hover:bg-[#214999] border-2 border-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#4B68AE]"
                    onClick={transferERC721}
                  >
                    ERC 721
                  </button>
                  <div>
                    <label
                      htmlFor="email"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Address
                    </label>
                    <div className="mt-1">
                      <input
                        type="text"
                        value={toTransferAddressERC721}
                        onChange={(e) =>
                          setToTransferAddressERC721(e.target.value)
                        }
                        className="block w-full rounded-md border-gray-200 shadow-sm focus:border-[#4B68AE] focus:ring-[#4B68AE] sm:text-sm"
                        placeholder="starts with 0x"
                      />
                    </div>
                  </div>
                  <div>
                    <label
                      htmlFor="email"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Token Id
                    </label>
                    <div className="mt-1">
                      <input
                        type="text"
                        value={tokenIdERC721}
                        onChange={(e) => setERC721TokenId(e.target.value)}
                        className="block w-full rounded-md border-gray-200 shadow-sm focus:border-[#4B68AE] focus:ring-[#4B68AE] sm:text-sm"
                        placeholder="amount must be number"
                      />
                    </div>
                  </div>
                  <div>
                    <label
                      htmlFor="email"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Contract Address
                    </label>
                    <div className="mt-1">
                      <input
                        type="text"
                        value={contractERC721Address}
                        onChange={(e) =>
                          setERC721ContractAddress(e.target.value)
                        }
                        className="block w-full rounded-md border-gray-200 shadow-sm focus:border-[#4B68AE] focus:ring-[#4B68AE] sm:text-sm"
                        placeholder="0x"
                      />
                    </div>
                  </div>
                </Stack>
              </>
            )}

            {/* <div className="w-full mt-10">
              <p className="text-black mt-5">{t("text.output")}: </p>
              <div className="overflow-hidden rounded-lg bg-white shadow mt-2">
                <div className="bg-[#fffacd] px-4 py-5 sm:p-6 whitespace-pre-line break-words">
                  {signInfo ? signInfo : t("text.nothing")}
                </div>
              </div>
            </div> */}

            {/* {signInfo && (
              <button
                type="button"
                className="inline-flex mt-5 mb-64 items-center px-3 py-1.5 text-xs font-medium rounded-xl shadow-sm text-white bg-[#4B68AE] hover:bg-[#214999] border-2 border-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#4B68AE]"
                onClick={async () => {
                  await navigator.clipboard.writeText(signInfo);
                  setIsCopy(true);
                }}
              >
                {isCopy ? t("buttons.copied") : t("buttons.copy")}
              </button>
            )} */}

            {Object.keys(txResult).length > 0 && (
              <p className="text-black mt-5">
                {t("text.transaction_output")}:{" "}
              </p>
            )}
            {Object.keys(txResult).length > 0 &&
              Object.keys(txResult).map((x) => (
                <div
                  className="overflow-hidden rounded-lg bg-white shadow mt-2"
                  key={x}
                >
                  <div className="bg-[#fffacd] px-4 py-5 sm:p-6 whitespace-pre-line break-words">
                    {x}: {txResult[x]}
                  </div>
                </div>
              ))}
          </div>
        ) : (
          <div className="mt-[15%] text-center">
            {loading ? (
              <LoadingV2 />
            ) : (
              <button
                type="button"
                className="w-[150px] mx-auto px-6 py-4 font-semibold rounded-xl shadow-sm text-white bg-[#4B68AE] hover:bg-[#214999] border-2 border-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#4B68AE]"
                onClick={login}
              >
                Login 3.0
              </button>
            )}
          </div>
        )}
      </header>

      <Toaster
        toastOptions={{
          className: "toaster",
        }}
        position="top-center"
        reverseOrder={false}
      />
    </div>
  );
};

export default Embed;
