// import Upbond from "../embed/upbondEmbed.esm";
import Upbond from "@upbond/upbond-embed";
import Web3 from "web3";
import Web3Token from "web3-token";
import { ethers } from "ethers";
import { toast } from "react-hot-toast";
import { network } from "../config";

class UpbondEmbed {
  // Initials
  upbond = null;

  web3 = null;

  // you can also using another envs.
  env = `${process.env.REACT_APP_EMBED_BUILD_ENV}`;

  provider = null;

  isLoggedIn = false;

  initialized = false;

  networks = network;

  constructor() {
    this.upbond = new Upbond({});
    this.web3 = new Web3();
    this.provider = null;
  }

  async init() {
    if (this.upbond instanceof Upbond) {
      await this.upbond.init({
        buildEnv: this.env,
        network: this.networks,
        isUsingDirect: true,
        loginConfig: {
          "upbond-line": {
            loginProvider: "upbond-line",
            verifier: process.env.REACT_APP_LINE_VERIFIER,
            typeOfLogin: "line",
            name: "LINE",
            description: "UPBOND LINE",
            clientId: process.env.REACT_APP_LINE_CLIENT_ID,
            jwtParameters: {
              domain: process.env.REACT_APP_LOGIN_UPBOND_DOMAIN,
              clientId: process.env.REACT_APP_LINE_CLIENT_ID,
              connection: "line"
            }
          },
          "upbond-google": {
            loginProvider: "upbond-google",
            verifier: process.env.REACT_APP_GOOGLE_VERIFIER,
            typeOfLogin: "jwt",
            name: "Google",
            description: "UPBOND GOOGLE",
            clientId: process.env.REACT_APP_GOOGLE_CLIENT_ID,
            jwtParameters: {
              domain: process.env.REACT_APP_LOGIN_UPBOND_DOMAIN,
              clientId: process.env.REACT_APP_GOOGLE_CLIENT_ID,
              connection: "google"
            }
          },
          "upbond-otp": {
            loginProvider: "upbond-otp",
            verifier: process.env.REACT_APP_OTP_VERIFIER,
            typeOfLogin: "jwt",
            name: "otp",
            description: "UPBOND otp",
            clientId: process.env.REACT_APP_OTP_CLIENT_ID,
            jwtParameters: {
              domain: process.env.REACT_APP_LOGIN_UPBOND_DOMAIN,
              client_id: process.env.REACT_APP_OTP_CLIENT_ID,
              clientId: process.env.REACT_APP_OTP_CLIENT_ID,
              connection: "otp"
            }
          }
        },
        whiteLabel: {
          walletTheme: {
            lang: localStorage.getItem("lang"),
            logo: "https://i.ibb.co/L6vHB5d/company-logo-sample.png",
            name: "Company",
            buttonLogo:
              "https://auth-service-public.s3.ap-northeast-1.amazonaws.com/assets/sample-button-logo.png",
            isActive: true,
            modalColor: "#fffff",
            bgColor: "#4B68AE",
            bgColorHover: "#214999",
            textColor: "#f3f3f3",
            textColorHover: "#214999",
            upbondLogin: {
              globalBgColor: "#ffffff",
              globalTextColor: "#4B68AE",
            },
          },
        },
        widgetConfig: {
          showAfterLoggedIn: true,
          showBeforeLoggedIn: false,
        },
        consentConfiguration: {
          enable: true,
          config: {
            publicKey: "0x02801900ccce1790eb73b5df66cd75d5e595fea9b9b21ef9f9abfd0ecf41dc8385",
            scope: ["email", "name", "birthday"],
            origin: window.location.origin,
          }
        }
      });
      console.log("UPBOND Embed initialized!");
      this.initialized = true;
      this.provider = upbondServices.upbond.provider;
    }
  }

  async login() {
    try {
      if (this.upbond instanceof Upbond && this.web3 instanceof Web3) {
        const _provider = await this.upbond.login(); // login using upbond
        this.web3.setProvider(this.upbond.provider);

        const accounts = await this.web3.eth.getAccounts();

        this.isLoggedIn = true;
        this.provider = _provider;
        return {
          msg: "success",
          data: _provider,
          accounts,
          // ... anything that you want to returns
        };
      }
    } catch (error) {
      console.log(error, "@errorOnReactProject?");
      toast.error(error.message || "Some error occured");
      return {
        msg: error.message || "Failed to login",
        data: null,
      };
    }
  }

  async logout() {
    try {
      if (this.upbond instanceof Upbond) {
        await this.upbond.logout();
        await this.upbond.cleanUp();
        window.location.reload();

        return {
          msg: "success",
          data: true,
        };
      }
    } catch (error) {
      toast.error(error.message || "Some error occured");
      return {
        msg: error.message || "Failed to login",
        data: null,
      };
    }
  }

  async getUserInfo() {
    if (this.upbond instanceof Upbond) {
      try {
        const userInfo = await this.upbond.getUserInfo();
        return userInfo;
      } catch (error) {
        toast.error(error.message || "Some error occured");
        throw new Error(error);
      }
    }
  }

  async signTransaction(msg = "", account) {
    if (this.web3 instanceof Web3) {
      try {
        this.web3.setProvider(this.upbond.provider);
        const sign = await this.web3.eth.sign(msg, account);
        return sign;
      } catch (error) {
        console.error(error);
        toast.error(error.message || "Some error occured");
        return null;
      }
    }
  }

  async signWeb3Token(account) {
    try {
      const ether = new ethers.providers.Web3Provider(this.upbond.provider);
      const signer = await ether.getSigner();
      const sign = await Web3Token.sign(
        async (msg) => {
          if (this.web3 instanceof Web3) {
            return await signer.signMessage(msg, account);
          }
        },
        {
          expires_in: "3 days",
          expiration_time: new Date(new Date().getTime() + 5 * 24 * 60 * 60 * 1000),
          nonce: Math.ceil(Math.random() * 10),
        }
      );
      return sign;
    } catch (error) {
      toast.error(error.message || "Some error occured");
      return;
    }
  }
}

const upbondServices = new UpbondEmbed();

export default upbondServices;
