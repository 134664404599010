import React from 'react'

const TutorialContent = ({ title, content, button, onClickNext, curStep, breakpoint, heightpoint, steps, setRun, t }) => {

  return (
    <>
      {!!curStep && <span className={`text-base font-normal text-center ${heightpoint > 600 ? "mb-8" : "mb-2"}`}>{`${curStep}/${steps.length - 1}`}</span>}

      <div className={`w-full flex flex-col items-center h-full justify-between px-4 py-0 pt-3 ${!!curStep || heightpoint < 600 ? "" : "mt-8"}`}>
        <div className="mb-2">
          <span className={` ${breakpoint > 1024 && heightpoint > 600 ? "text-3xl" : "text-xl"} font-bold text-center`}>
            {title.split("\n").map((line, i) => (
              <p key={i}>{line}</p>
            ))}
          </span>
        </div>

        <div className={`my-4 mb-8`}>
          <span className={`${breakpoint > 1024 && heightpoint > 600 ? "text-xl" : "text-base"} font-normal text-center `} >
            {content.split("\n").map((line, i) => (
              <p key={i}>{line}</p>
            ))}
          </span>
        </div>

        <div className="w-full text-base font-normal flex justify-center">
          {!curStep && (
            <div className={`h-full w-5/12 flex flex-col justify-end items-end mr-4`}>
              <button
                className={`${breakpoint > 1024 ? "w-max" : "w-full"} min-w-[100px] bg-[#FF008A] px-4 rounded-3xl text-white h-12`}
                onClick={() => setRun(false)}
              >
                {t("tour.button_skip_tour")}
              </button>
            </div>
          )}
          <div className={`h-full flex flex-col justify-center ${!!curStep ? "items-center w-10/12" : "w-5/12"}`}>
            <button
              className={`${breakpoint > 1024 ? "w-max" : "w-full"} min-w-[100px] bg-white px-4 rounded-3xl text-[#FF008A] h-12`}
              onClick={onClickNext}
            >
              {button}
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default TutorialContent